import * as React from 'react';
import ThreeColumnFormField from 'styleguide/components/forms/Field/ThreeColumnFormField';
import { Field, useFormikContext } from 'formik';
import Input from 'styleguide/components/Formik/Input/Input';
import range from 'lodash-es/range';
import { getYear } from 'date-fns';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';
import Checkbox from '../Checkbox/Checkbox';
import Label from '../Label/Label';
import UserContext from 'contexts/UserContextContainer/UserContext';

interface Props {
  prefix: string;
}

const appendName = (prefix: string, name: string) => {
  if (prefix === '') {
    return name;
  }
  return `${prefix}.${name}`;
};

const extractFormikProperty = (formik, prefix: string, property: string) =>
  formik.values?.[prefix]?.[property] || formik.values?.[property];

const CreditCardForm = ({ prefix }: Props) => {
  const { currentUser } = React.useContext(UserContext);
  const formik = useFormikContext<object>();

  const selectedYear = parseInt(extractFormikProperty(formik, prefix, 'year'), 10);
  const isCurrentYearSelected = getYear(new Date()) === selectedYear;
  if (
    isCurrentYearSelected &&
    parseInt(extractFormikProperty(formik, prefix, 'month'), 10) < new Date().getMonth() + 1
  ) {
    formik.setFieldValue(appendName(prefix, 'month'), '');
  }
  const monthOptions = range(isCurrentYearSelected ? new Date().getMonth() + 1 : 1, 13).map(i => ({
    name: i.toString(),
    key: i,
  }));

  return (
    <>
      <div data-cy="creditCardForm.title" className="paragraph-bold-mobile mb-2 mt-4">
        Enter Credit Card Information
      </div>
      <Field
        inPlaceError
        size="sm"
        name={appendName(prefix, 'number')}
        component={Input}
        data-cy="creditCardForm.numberInput"
        label="Card number"
        cc
        required
        autoComplete="cc-number"
      />
      <Field
        inPlaceError
        size="sm"
        name={appendName(prefix, 'name')}
        component={Input}
        data-cy="creditCardForm.nameInput"
        label="Name on Card"
        required
        autoComplete="cc-name"
      />

      <ThreeColumnFormField className="p-0 md:!mt-1">
        <Field
          autoSelect
          autoSelectMinimumLength={2}
          autoSelectComparator={(inputValue: string, optionValue: string) => {
            const inputMonth = parseInt(inputValue, 10);
            const optionMonth = parseInt(optionValue, 10);
            return inputMonth === optionMonth;
          }}
          name={appendName(prefix, 'month')}
          component={Combobox}
          data-cy="creditCardForm.monthCombobox"
          label="Month"
          options={monthOptions}
          inPlaceError
          required
          size="sm"
          iconColor="blue"
          autoComplete="cc-exp-month"
        />
        <Field
          autoSelect
          autoSelectComparator={(inputValue: string, optionValue: string) => {
            let inputYear = parseInt(inputValue, 10);
            const optionYear = parseInt(optionValue, 10);
            if (inputValue.length <= 2) {
              inputYear += 2000;
            }
            return inputYear === optionYear;
          }}
          name={appendName(prefix, 'year')}
          component={Combobox}
          data-cy="creditCardForm.yearCombobox"
          label="Year"
          options={range(getYear(new Date()), getYear(new Date()) + 10).map(year => ({
            name: year.toString(),
            key: year,
          }))}
          inPlaceError
          size="sm"
          required
          iconColor="blue"
          autoComplete="cc-exp-year"
        />

        <Field
          data-cy="creditCardForm.cvvInput"
          inPlaceError
          size="sm"
          name={appendName(prefix, 'verificationValue')}
          component={Input}
          label="Security Code"
          required
          autoComplete="cc-csc"
        />
      </ThreeColumnFormField>
      {currentUser && (
        <div className="mb-1 flex justify-start space-x-2">
          <Field
            data-cy="saveCreditCard"
            name={appendName(prefix, 'saveCreditCard')}
            component={Checkbox}
            size="sm"
            className="-mt-1"
          />
          <Label placement="left" className="font-weight-bold font-hvBold">
            Save Credit Card?
          </Label>
        </div>
      )}
    </>
  );
};
export default CreditCardForm;
